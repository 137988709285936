import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dashboardRoutes = [];  

const useStyles = makeStyles(styles);

const content = {
    "tnc": "Terms and Conditions",
    "tnc_intro": "Intoduction",
    "tnc_intro_para1": "When you use Qko, you trust us with your personal data. We’re committed to keeping that trust. That starts with helping you understand our privacy practices. This notice describes the personal data we collect, how it’s used and shared, and your choices regarding this data. We recommend that you read this along with our privacy overview, which highlights key points about our privacy practices.",
    "tnc_last_modified": "Last modified: ",
    "tnc_last_modified_date": "12th November, 2022",
    "tnc_effective": "Effective Date: ",
    "tnc_effective_date": "12th November, 2022",
    "tnc_overview_heading": "Overview",
    "tnc_heading_scope": "SCOPE",
    "tnc_scope_para1": "This notice applies to users of Qko's services anywhere in the world, including users of Qko's apps, websites, features, or other services.",
    "tnc_scope_para2": "We will also accept the payments by call and toll free number and link will be feeded in the Qko app. This notice describes how Qko and its affiliates collect and use personal data. This notice applies to all users of our apps, websites, features, or other services anywhere in the world unless covered by a separate privacy notice, such as the Qko Driver Privacy Notice. This notice specifically applies to:",
    "tnc_scope_list1_heading": "Riders:",
    "tnc_scope_list1": "individuals who request or receive transportation, including those who receive transportation requested by another individual",
    "tnc_scope_list2_heading": "Drivers: ",
    "tnc_scope_list2": "individuals who provide, or submit applications to Qko to provide, transportation individually or through partner transportation companies",
    "tnc_scope_para3": "This notice also governs Qko's other collections of personal data in connection with Qko's services. For    example, we may collect the contact information of individuals who use accounts owned Qko for Business customers or of owners, or we may collect other personal data in connection with our mapping technology and features.",
    "tnc_scope_para4": "All those subject to this notice are referred to as 'users' in this notice.",
    "tnc_scope_para5": "In addition, please note the following:",
    "tnc_scope_para6": "For riders without an Qko account: The personal data of riders who take trips arranged by the owners of an AmbiPalm account, such as when a rider takes a ride arranged by the owner of an AmbiPalm Health account (such as a hospital or healthcare provider), is processed in accordance with our contract with the owner of that account, and not as otherwise described in this notice. Such processing may include sharing of rider location data with the owner of that account. Please contact the owner of such account for more information.",
    "tnc_scope_para7": "Our data practices are subject to applicable laws in the places in which we operate. This means that we engage in the practices described in this notice in a particular country or region only if permitted under the laws of those places. Please contact us through the addresses below with any questions regarding our practices in a particular country or region.",
    "tnc_data_controller_heading": "DATA CONTROLLER AND TRANSFER",
    "tnc_data_controller_para1": "Qkoservices Private Limited is the data controller for the personal data collected in connection with use of Qko's services anywhere.",
    "tnc_data_collections": "Data collections and uses",
    "tnc_data_collections_para1": "The data we collect",
    "tnc_data_collections_para2": "Qko collects: ",
    "tnc_data_collections_list1": "Data provided by users to Qko, such as during account creation",
    "tnc_data_collections_list2": "Data created during use of our services, such as location, app usage, and device data",
    "tnc_data_collections_list3": "Data from other sources, such as Qko partners and third parties that use Qko APIs",
    "tnc_data_collections_para3": "The following data is collected by or on behalf of Qko:",
    "tnc_data_collections_para4": "Data provided by users. This includes:",
    "tnc_data_collections_list4_heading": "User Profile: ",
    "tnc_data_collections_list4": "We collect data when users create or update their Qko accounts. This may include their name, email phone number, login name and password, address, profile picture, payment or banking information (including related payment verification information), government identification documents, including driver's license numbers and images, birthdate, signature, and photo. This also includes vehicle or insurance information of drivers partners, and user settings. We may use the photos submitted by drivers partners to verify their identities, such as through facial recognition technologies. For more information, please see the section titled 'How we use personal data.'",
    "tnc_data_collections_list5_heading": "Background check and identity verification: ",
    "tnc_data_collections_list5": "Background check and identity verification: We collect background check and identity verification information for drivers partners. This may include information such as driver history or criminal record (where permitted by law), and right to work. This information may be collected by an authorized vendor on Qko's behalf.",
    "tnc_data_collections_list6_heading": "Demographic data: ",
    "tnc_data_collections_list6": "We may collect demographic data about users, including through user surveys. In some countries, we may also receive demographic data about users from third parties.",
    "tnc_data_collections_list7_heading": "User content: ",
    "tnc_data_collections_list7": "User content: We collect the information users submit when they contact Qko customer support, provide ratings or compliments for other users, or otherwise contact Qko. This may include feedback, photographs or other recordings collected by users.",
    "tnc_data_collections_para5": "Data created during use of our services. This includes: ",
    "tnc_data_collections_list8_heading": "Location data: ",
    "tnc_data_collections_list8": "We collect precise or approximate location data from a user's mobile device if by the user to do so. For driver partners Qko collects this data when the Qko app is running in the foreground (app open and on-screen) or background (app open but not on-screen) of their mobile device. However, this may affect some functionality available in the Qko apps. For example, a user who has not enabled location data collection will have to manually enter their address. In addition, the location data collected from a driver or during a trip will be linked to the rider's account, even if they have not enabled location data to be collected from their device, including for purposes of receipt generation, customer support, fraud detection, insurance, and litigation.",
    "tnc_data_collections_list9_heading": "Transaction information: ",
    "tnc_data_collections_list9": "We collect transaction information related to the use of our services, including the type of services requested or provided, order details, date and time the service was provided, amount charged, distance travelled and payment method. Additionally, if someone uses your promotion code, we may associate your name with that person.",
    "tnc_data_collections_list10_heading": "Usage data: ",
    "tnc_data_collections_list10": "We collect data about how users interact with our services. This includes data such as access dates and times, app features or pages viewed, app crashes and other system activity, type of browser, and third-party sites or services used before interacting with our services. In some cases, we collect this data through cookies, pixels, tags, and similar tracking technologies that create and maintain unique identifiers. To learn more about these technologies, please see our Cookie Notice.",
    "tnc_data_collections_list11_heading": "Device data: ",
    "tnc_data_collections_list11": "We may collect data about the devices used to access our services, including the hardware models, device IP address, operating systems and versions, software, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion data, and mobile network data.",
    "tnc_data_collections_list12_heading": "Communications data: ",
    "tnc_data_collections_list12": "We enable users to communicate with each other and Qko through Qko's mobile apps and websites. For example, we enable drivers and riders to call, text, or send other files to each other (generally without disclosing their telephone numbers to each other). To provide this service, Qko receives some data regarding the calls, texts, or other communications, including the date and time of the communications and the content of the communications. Qko may also use this data for customer support services (including to resolve disputes between users), for safety and security purposes, to improve our products and services, and for analytics.",
    "tnc_data_collections_para6": "Data from other sources. This includes:",
    "tnc_data_collections_list13": "User feedback, such as ratings, feedback, or compliments.",
    "tnc_data_collections_list14": "Users participating in our referral programs. For example, when a user refers another person, we receive the referred person's personal data from that user.", 
    "tnc_data_collections_list15": "Qko account owners who request services for or on behalf of other users, or who enable such users to request or receive services through their accounts. This includes owners of Qko for Business accounts.", 
    "tnc_data_collections_list16": "Users or others providing information in connection with claims or disputes.", 
    "tnc_data_collections_list17": "Qko business partners through which users create or access their Qko account such as payment providers, social media services, or apps or websites that use Qko APIs or whose APIs Qko uses.", 
    "tnc_data_collections_list18": "Vendors who help us verify users' identity, background information, and eligibility to work, for regulatory, safety, and security purposes.", 
    "tnc_data_collections_list19": "Insurance, vehicle, or financial services providers for drivers and/or delivery partners.", 
    "tnc_data_collections_list20": "Partner transportation companies (for drivers partners who use our services through an account associated with such a company).", 
    "tnc_data_collections_list21": "Publicly available sources, Local database,", 
    "tnc_data_collections_list22": "Marketing service providers.", 
    "tnc_data_collections_list23": "Marketing service providers.",
    "tnc_data_collections_list24": "Qko may combine the data collected from these sources with other data in its possession.",

    "tnc_data_use_para1": "How we use personal data",
    "tnc_data_use_para2": "Qko collects and uses data to enable reliable and convenient transportation.. We also use the data we collect:",
    "tnc_data_use_list1": "To enhance the safety and security of our users and services",
    "tnc_data_use_list2": "For customer support, for their facilities to be provided by call support",
    "tnc_data_use_list3": "For research and development",
    "tnc_data_use_list4": "To enable communications between users",
    "tnc_data_use_list5": "To send marketing and non-marketing communications to users",
    "tnc_data_use_list6": "In connection with legal proceedings",
    "tnc_data_use_para3": "Qko does not sell or share user personal data with third parties for their direct marketing, except with users' consent. Qko uses the data it collects for purposes including:",
    "tnc_data_use_para4": "1. Providing services and features. Qko uses the data we collect to provide, personalize, maintain, and improve our products and services.",
    "tnc_data_use_para5": "This includes using the data to:",
    "tnc_data_use_list7": "Create and update users' accounts.",
    "tnc_data_use_list8": "Verify driver partners' identity, background history, and eligibility to work.",
    "tnc_data_use_list9": "Enable transportation, deliveries, and other services.",
    "tnc_data_use_list10": "Offer, process, or facilitate payments for our services.",
    "tnc_data_use_list11":"Offer, obtain, provide, or facilitate insurance, vehicle, invoicing, or financing solutions in connection with our services.",
    "tnc_data_use_list12": "Track and share the progress of rides.",
    "tnc_data_use_list13": "Enable features that allow users to share information with other people, such as when riders submit a compliment about a driver, when users provide feedback, refer a friend to Qko, or share ETA and location with their contacts.",
    "tnc_data_use_list14": "Enable Accessibility features that make it easier for users with disabilities to use our services, such as those that enable deaf or hard-of-hearing drivers to alert their riders of their disabilities, allow only text messages from riders, and enable receipt of flashing trip request notifications instead of sound notifications on their mobile device.",
    "tnc_data_use_list15": "Perform internal operations necessary to provide our services, including to troubleshoot software bugs operational problems; to conduct data analysis, testing, and research; and to monitor and analyze usage and activity trends.",
    "tnc_data_use_para6": "2. Safety and security",
    "tnc_data_use_para7": "We use personal data to help maintain the safety, security, and integrity of our services and users. This includes:",
    "tnc_data_use_list16": "Screening riders, driver partners before enabling their use of our services and at subsequent intervals, including through reviews of background checks, where permitted by law, to help prevent use of our services by unsafe drivers and/or riders.",
    "tnc_data_use_list17": "Using data from drivers' partners' devices to help identify unsafe driving behaviour such as speeding or harsh braking and acceleration, and to inform them of safer driving practices. We also use data from driver partners' devices to verify the type of vehicles they used to provide rides..",
    "tnc_data_use_list18": "Using device, location, profile, usage, and other data to prevent, detect, and combat fraud or unsafe activities.",
    "tnc_data_use_list19": "Using user ratings and feedback to encourage compliance with our Community Guidelines and as grounds for deactivating drivers and delivery partners with low ratings or who otherwise violated such guidelines in certain countries.",
    "tnc_data_use_para8": "3. Customer support",
    "tnc_data_use_para9": "Qko uses the information we collect (including recordings of customer support calls with notice to and consent of the user) to provide customer support, including to:",
    "tnc_data_use_list20": "Direct questions to the appropriate customer support person",
    "tnc_data_use_list21": "Investigate and address user concerns",
    "tnc_data_use_list22": "Monitor and improve our customer support responses and processes",
    "tnc_data_use_para10": "4. Research and development.",
    "tnc_data_use_para11": "We may use the data we collect for testing, research, analysis, product development, and machine learning to improve the user experience. This helps us to improve and enhance the safety and security of our services, improve our ability to prevent the use of our services for illegal or improper purposes, develop new features and products, and facilitate insurance and finance solutions in connection with our services, building a healthy ecosystem for future.",
    "tnc_data_use_para12": "5. Enabling communications between users. ",
    "tnc_data_use_para13": "For example, a driver may message or call a rider to confirm a pickup location, a rider may contact to retrieve a lost item.",
    "tnc_data_use_para14": "6. Marketing.",
    "tnc_data_use_list23": "Qko may use the data we collect to market our services to our users. This includes sending users communications about Qko services, features, promotions, sweepstakes, studies, surveys, news, updates, and events.",
    "tnc_data_use_list24": "We may also send communications to our users about products and services offered by Qko partners. Although we may send users communications about Qko partners' products and services, we do not sell users' personal data to or with, such partners or others for purposes of their own direct marketing or advertising, except with users' consent.",
    "tnc_data_use_list25": "We may use the data we collect to personalize the marketing communications (including advertisements) that we send, including based on user location, past use of Qko's services, and user preferences and settings.",
    "tnc_data_use_list26": "We may also send users communications regarding elections, ballots, referenda, and other political and notice processes that relate to our services. For example, Qko has notified some users by email of ballot measures or pending legislation relating to the availability of Qko services in those users' areas.",
    "tnc_data_use_para15": "7. Non-marketing communications.",
    "tnc_data_use_para16": "Qko may use the data we collect to generate and provide users with receipts; inform them of changes to our terms, services, or policies; or send other communications that aren't for the purpose of marketing the services or products of Qko's or its partners.",
    "tnc_data_use_para17": "8. Legal proceedings and requirements. ",
    "tnc_data_use_para18": "We may use the personal data we collect to investigate or address claims or disputes relating to use of Qko's services, or as otherwise allowed by applicable law, or as requested by regulators, government entities, and official inquiries.",
    "tnc_data_use_para19": "9. Automated decision-making",
    "tnc_data_use_para20": "We use personal data to make automated decisions relating to use of our services. This includes:",
    "tnc_data_use_list27": "Enabling dynamic pricing, in which the price of a ride is determined based on constantly varying factors such as the estimated time and distance, the predicted route, estimated traffic, and the number of riders and drivers using Qko at a given moment.",
    "tnc_data_use_list28": "Matching available drivers to users requesting services. Users can be matched based on availability, proximity, and other factors.",
    "tnc_data_use_list29": "Determining drivers and deactivating drivers, riders, with low ratings.",
    "tnc_data_use_list30": "Deactivating users who are identified as having engaged in fraud or activities that may otherwise harm Qko, its users, and others. In some cases, such as when a user is determined to be abusing Qko 's referral program, such behaviour may result in automatic deactivation.",
    "tnc_data_use_list31": "Using driver location information, and communications between riders and drivers, to identify cancellation fees earned or induced through fraud. For example, if we determine by using such information that a driver is delaying a rider pickup in order to induce a cancellation, we will not charge the rider a cancellation fee and will adjust the amounts paid to the driver to omit such a fee. To object to such ad•ustment please contact Qko customer support.in any case driver found that he is to cancel rides or the system by any means he will be blacklisted by the company.",

    "tnc_cookies_para1": "Cookies and third-party technologies",
    "tnc_cookies_para2": "Qko and its partners use cookies and other identification technologies on our apps, websites, emails, and online ads for purposes described in this notice.",
    "tnc_cookies_para3": "Cookies are small text files that are stored on browsers or devices by websites, apps, online media, and advertisements. Qko uses cookies and similar technologies for purposes such as:",
    "tnc_cookies_list1": "Authenticating users",
    "tnc_cookies_list2": "Remembering user preferences and settings",
    "tnc_cookies_list3": "Determining the popularity of content",
    "tnc_cookies_list4": "Delivering and measuring the effectiveness of advertising campaigns",
    "tnc_cookies_list5": "Analyzing site traffic and trends, and generally understanding the online behavior and interests of people who interact with our services",
    "tnc_cookies_list6": "We may also allow others to provide audience measurement and analytics services for us, to serve advertisements on our behalf across the Internet, and to track and report on the performance of those advertisements. These entities may use cookies, web beacons, SDKs, and other technologies to identifythe devices used by visitors to our websites, as well as when they visit other online sites and services.",

    "tnc_data_sharing_para1": "Data sharing and disclosure",
    "tnc_data_sharing_para2": "Some of Qko's products, services, and features require that we share data with other users or at a user's request. We may also share data with our affiliates, subsidiaries, and partners, for legal reasons or in connection with claims or disputes.",
    "tnc_data_sharing_para3": "Qko may share the data we collect:",
    "tnc_data_sharing_para4": "With other users",
    "tnc_data_sharing_para5": "This includes sharing:",
    "tnc_data_sharing_list7": "Riders' first name, rating, and pickup and/or drop-off locations with drivers.",
    "tnc_data_sharing_list8": "For drivers, we may share data with the rider(s), including name and photo; vehicle make, model, colour, license plate, and vehicle photo; location (before and during trip); average rating provided by users; total number of trips; length of use of the Qko app; contact information (depending upon applicable laws); and driver profile, including compliments and other feedback submitted by past users. We also provide riders receipts containing information such as a breakdown of amounts charged, driver first name, photo, route map, and such other information required on invoices in the country or region where the driver operates.",
    "tnc_data_sharing_list9": "For those who participate in Qko's referral program, we share certain personal data of referred users, such as trip count, with the user who referred them, to the extent relevant to determining the referral bonus.",
    "tnc_data_sharing_para6": "At the user's request",
    "tnc_data_sharing_para7": "This includes sharing data with:",
    "tnc_data_sharing_list10": "Other people at the user's request. For example, we share a user's ETA and location with a friend when requested by that user.",
    "tnc_data_sharing_list11": "Qko business partners. For example, if a user requests a service through a partnership or promotional offering made by a third party, Qko may share certain data with those third parties. This may include, for example, other services, platforms, apps or websites that integrate with our APIs; vehicle suppliers or services; those with an API or service with which we integrate; or other Qko business partners and their users in connection with promotions, contests, or specialized services.",
    "tnc_data_sharing_para8": "With the general public",
    "tnc_data_sharing_para9": "Questions or comments from users submitted through public forums such as Qko blogs and Qko social media pages may be viewable by the public, including any personal data included in the questions or comments submitted by a user.",
    "tnc_data_sharing_para10": "With the Qko account owner",
    "tnc_data_sharing_para11": "If a user requests transportation using an account owned by another party, we may share their trip information, including real-time location data, with the owner of that account. This occurs, for example, when:",
    "tnc_data_sharing_list12": "A driver uses an account owned by or associated with an Qko partner transportation company",
    "tnc_data_sharing_list13": "A rider takes a trip arranged by a friend or under a Family Profile",
    "tnc_data_sharing_para12": "With Qko subsidiaries and affiliates",
    "tnc_data_sharing_para13": "We share data with our subsidiaries and affiliates to help us provide our services or conduct data processing on our behalf.",
    "tnc_data_sharing_para14": "With Qko service providers and business partners",
    "tnc_data_sharing_para15": "Qko provides data to vendors, consultants, marketing partners, research firms, and other service providers or business partners. These include:",
    "tnc_data_sharing_list14": "Payment processors and facilitators",
    "tnc_data_sharing_list15": "Background check and identity verification providers (drivers only)",
    "tnc_data_sharing_list16": "Cloud storage providers",
    "tnc_data_sharing_list17": "Marketing partners and marketing platform providers, including social media advertising services",
    "tnc_data_sharing_list18": "Data analytics providers",
    "tnc_data_sharing_list19": "Research partners, including those performing surveys or research projects in partnership with Qko or on Qko's behalf",
    "tnc_data_sharing_list20": "Vendors that assist Qko to enhance the safety and security of its apps",
    "tnc_data_sharing_list21": "Consultants, lawyers, accountants, and other professional service providers",
    "tnc_data_sharing_list22": "Fleet partners",
    "tnc_data_sharing_list23": "Insurance and financing partners",
    "tnc_data_sharing_list24": "Airports",
    "tnc_data_sharing_list25": "Lime and other local providers",
    "tnc_data_sharing_list26": "Vehicle solution vendors or third-party vehicle suppliers",
    "tnc_data_sharing_para16": "For legal reasons or in the event of a dispute",
    "tnc_data_sharing_para17": "Qko may share users' personal data if we believe it's required by applicable law, regulation, operating license or agreement, legal process or governmental request, or where the disclosure is otherwise appropriate due to safety or similar concerns. This includes sharing personal data with law enforcement officials, public health officials, other government authorities, airports (if required by the airport authorities as a condition of operating on airport property), or other third parties as necessary to enforce our Terms of Service, user agreements, or other policies; to protect Qko's rights or property or the rights, safety, or property of others; or in the event of a claim or dispute relating to the use of our services. If you use another person's credit card, we may be required by law to share your personal data, including trip or order information, with the owner of that credit card.",
    "tnc_data_sharing_para18": "This also includes sharing personal data with others in connection with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or into another company.",
    "tnc_data_sharing_para19": "With consent",
    "tnc_data_sharing_para20": "Qko may share a user's personal data other than as described in this notice if we notify the user and they consent to the sharing.",
    "tnc_data_retention_deletion_para1": "Data retention and deletion",
    "tnc_data_retention_deletion_para2": "Qko retains user profile, transaction, and other personal data for as long as a user maintains their Qko account.",
    "tnc_data_retention_deletion_para3": "Qko may retain certain user data after receiving an account deletion request if necessary, such as to comply with legal requirements.",
    "tnc_data_retention_deletion_para4": "Qko retains user transaction, and other information for as long as a user maintains their Qko account. For drivers, Qko also retains vehicle and background check information (to the extent permitted by law) for as long as they maintain their Qko account.",
    "tnc_data_retention_deletion_para5": "Riders may request deletion of their account at any time in the Qko app, or through Qko's Help Center. Following such requests, Qko deletes the data that it is not required to retain for purposes of regulatory, tax, insurance, litigation, or other legal requirements. For example, Qko retains location, device, and usage data for these purposes for a minimum of 7 years; while it retains such data, it may also use it for purposes of safety, security, fraud prevention and detection, and research and development. In certain circumstances, Qko may be unable to delete a user's account, such as if there's an outstanding credit on the account or an unresolved claim or dispute. Upon resolution of the issue preventing deletion, Qko will delete the account as described above.",
    "tnc_data_retention_deletion_para6": "Qko may also retain certain information if necessary for purposes of safety, security, and fraud prevention. For example, if Qko deactivates a user's account because of unsafe behaviour or security incidents, Qko may retain certain information about that account to prevent that user from opening a new Qko account in the future.",
    "tnc_processing_para1": "Grounds for processing",
    "tnc_processing_para2": "We only collect and use personal data where we have lawful grounds to do so. These include processing user personal data to provide requested services and features, for purposes of Qko's legitimate interests or those of other parties, to fulfil our legal obligations, or based on consent.",
    "tnc_processing_para3": "We collect and use personal data only where we have one or more lawful grounds for doing so. Such grounds may vary depending on where our users are located, but generally include processing personal data:",
    "tnc_processing_para4": "To provide requested services and features",
    "tnc_processing_para5": "In order to provide our services, we must collect and use certain personal data. This includes: ",
    "tnc_processing_list1": "User profile data, which we use to establish and maintain user accounts; verify user identity; communicate with users about their trips, and accounts; and enable users to make payments or receive earnings",
    "tnc_processing_list2": "Background trip information, which is used to verify an applicant's eligibility to be a driver.",
    "tnc_processing_list3": "Driver location data, which we use to track trips and assist with navigation",
    "tnc_processing_list4": "Usage data, which is necessary to maintain, optimize, and enhance Qko's services, including to determine incentives, connect riders and drivers, and calculate costs of trips and driver earnings",
    "tnc_processing_list5": "Transaction information",
    "tnc_processing_list6": "Information relating to customer support",
    "tnc_processing_para6": "For purposes of the legitimate interests of Qko or other parties",
    "tnc_processing_para7": "This includes using personal data to maintain and enhance our users' safety and security. For example, we use personal data to prevent use of our services by users who have engaged in inappropriate or dangerous behaviour such as by retaining data of banned users to prevent their use of Qko's apps. We also use usage data to prevent of riders and drivers for whom there is risk of conflict (for instance, because they have been the subject of prior complaints from other users).",
    "tnc_processing_para8": "This also includes purposes such as combating fraud; improving our services, direct marketing, research, and development; and enforcing Qko's Terms of Service.",
    "tnc_processing_para9": "In addition, it includes using personal data to the extent necessary for the interests of other people or the general public, such as in connection with legal or insurance claims, and to rotect the rights and safety of others.",
    "tnc_processing_para10": "To fulfil Qko's legal obligations",
    "tnc_processing_para11": "For example, Qko is subject to laws and regulations in many cities that require it to collect and retain data about our users' trips, and to provide copies of such data to the government or other authorities. We collect and use personal data to comply with such laws.",
    "tnc_processing_para12": "Qko may also share data with law enforcement regarding criminal acts or threats to public safety, or requests by third parties pursuant to legal processes.",
    "tnc_processing_para13" : "With consent",
    "tnc_processing_para14" : "Qko may collect and use personal data based on the user's consent. For example, we may collect personal data through voluntary surveys. Responses to such surveys are collected on the basis of consent and will be deleted once no longer necessary for the purposes collected.",
    "tnc_processing_para15" : "A user who has provided consent to a collection or use of their personal data can revoke it at any time. However, the user will not be able to use any service or feature that requires collection or use of that personal data.",
    "tnc_choice_transparency_heading" : "CHOICE AND TRANSPARENCY",
    "tnc_choice_transparency_para1" : "Qko enables users to access and control the data that Qko collects, including through:",
    "tnc_choice_transparency_list1": "In-app settings",
    "tnc_choice_transparency_list2": "Device permissions",
    "tnc_choice_transparency_list3": "In-app ratings pages",
    "tnc_choice_transparency_list4": "Marketing opt-outs",
    "tnc_choice_transparency_para2": "Qko also enables users to request access to or copies of their data, changes or updates to their accounts, deletion of their accounts, or that Qko restrict its processing of user personal data.",
    "tnc_choice_transparency_para3": "Privacy settings",
    "tnc_choice_transparency_para4": "Privacy Settings menus in the Qko app for riders give them the ability to set or update their location-sharing preferences and their preferences for receiving mobile notifications from Qko. Information about these settings, how to set or change these settings, and the effect of turning off these settings is described below.",
    "tnc_choice_transparency_para5": "Location data",
    "tnc_choice_transparency_para6": "Qko uses riders' device location services to make it easier to get a ride whenever they need one. Location data helps improve our services, including pickups, navigation, and customer support.",
    "tnc_choice_transparency_para7": "Riders may enable or disallow Qko to collect location data from their mobile devices through the Settings > Privacy menus in the Qko app.",
    "tnc_choice_transparency_para8": "Riders can enable or disallow such collections through the settings on their mobile device.",
    "tnc_choice_transparency_para9": "Share Live Location (riders)",
    "tnc_choice_transparency_para10": "Riders who have enabled Qko to collect location data from their mobile device may also enable Qko to share their location with their driver from the time the ride is requested to the start of the trip. This can help improve pickups for both drivers and riders, particularly in crowded areas.",
    "tnc_choice_transparency_para11": "Riders may enable or disable this feature through the Settings > Privacy menus in the Qko app.",
    "tnc_choice_transparency_para12": "Emergency Data Sharing (riders)",
    "tnc_choice_transparency_para13": "Riders who have enabled Qko to collect location data from their mobile device may also enable the Emergency Data Sharing feature that shares data with emergency police, and fire. Such data includes approximate location at the time the emergency call was placed; the car's make, model, color, and license plate information; the rider's name and phone number; pickup and drop-off locations; and the driver's name.",
    "tnc_choice_transparency_para14": "Riders may enable or disable this feature through the Settings > Privacy menus.",
    "tnc_choice_transparency_para15": "Notifications: account and trip updates",
    "tnc_choice_transparency_para16": "Qko provides users with trip status notifications and updates related to activity on their account. These notifications are a necessary part of using the Qko app and cannot be disabled. However, users may choose the method by which they receive these notifications through the Settings > Privacy menus in the Qko app.",
    "tnc_choice_transparency_para17": "Notifications: Discount and News",
    "tnc_choice_transparency_para18": "Users may enable Qko to send push notifications about discounts and news from Qko. Push notifications may be enabled or disabled through the Settings > Privacy menus in the Qko app.",
    "tnc_choice_transparency_para19": "Device permissions",
    "tnc_choice_transparency_para20": "Most mobile device platforms (iOS, Android, etc.) have defined certain types of device data that apps cannot access without the device owner's permission, and these platforms have different methods for how that permission can be obtained. iOS devices notify users the first time the Qko app requests permission to access certain types of data and gives users the option to grant or refuse permission. Android devices notify users of the permissions that the Qko app seeks before their first use of the app, and use of the app constitutes a grant of such permission.",
    "tnc_choice_transparency_para21": "Ratings look-up",
    "tnc_choice_transparency_para22": "After every trip, drivers and riders are able to rate each other on a scale from I to 5. An average of those ratings is associated with a user's account and is displayed to other users for whom they provide or receive services. For example, rider ratings are available to drivers from whom they request transportation, and driver ratings are available to their riders.",
    "tnc_choice_transparency_para23": "This 2-way system holds everyone accountable for their behaviour. Accountability helps create a respectful, safe environment for drivers and riders. Riders can see their average rating in the main menu of the Qko app. Drivers can see their average rating after tapping their profile photo in the Qko Driver app.",
    "tnc_choice_transparency_para24": "Marketing opt-outs",
    "tnc_choice_transparency_para25": "Users may opt out of receiving promotional emails from Qko. Users may also opt out of receiving emails and other messages from Qko by following the unsubscribe instructions in those messages. We may still send users who have opted out non-promotional communications, such as receipts for rides or information about their account.",
    "tnc_choice_transparency_para26": "User data requests",
    "tnc_choice_transparency_para27": "Qko provides users with a variety of ways to learn about, control, and submit questions and comments about Qko's handling of their data To make a request.",
    "tnc_choice_transparency_para28": "Accessing data: Users can ask for an explanation of the data we collect from them and how we use it.",
    "tnc_choice_transparency_para29": "Receiving data: Users can ask for a copy of data that Qko collects from them with their consent or as necessary to provide our services.",
    "tnc_choice_transparency_para30": "Changing or updating data: Users can edit the name, phone number, email address, payment method, and photo associated with their account through the Settings menu in Qko's apps or driver portal. They may also ask that Qko change or update their data, including if they believe such data is inaccurate or incomplete.",
    "tnc_choice_transparency_para31": "Deleting data: Users may request deletion of their account at any time through the Settings > Privacy menus in the Qko app, or through Qko's website.",
    "tnc_choice_transparency_para32": "Objections, restrictions, and complaints: Users may request that we stop using all or some of their personal data, or that we limit our use of their data. Qko may continue to process data after such objection or request to the extent required or permitted by law.",
    "tnc_choice_transparency_para33": "In addition, depending on their location, users may have the right to file a complaint relating to Qko's handling of their personal data with the data protection authority in their country.",
    "tnc_update_heading": "Updates to this notice",
    "tnc_update_para1": "We may occasionally update this notice. Use of our services after an update constitutes consent to the updated notice to the extent permitted by law.",
    "tnc_update_para2": "We may occasionally update this notice. If we make significant changes, we will notify users in advance of the changes through the Qko apps or through other means, such as email. We encourage users to periodically review this notice for the latest information on our privacy practices. After such notice, use of our services by users in countries outside the European Union will be understood as consent to the updates to the extent permitted by law."
}

export default function TermsCondition(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings); 
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
      <div className={classes.container}>
            <br/>
            <h2  style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color: "#383838",textDecoration: "none"}}>{content.tnc}</h2>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{content.tnc_intro_para1}</p>
            {/* <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{t('privacy_policy_para2')}</p> */}
            {/* <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{t('privacy_policy_para3')}</p> */}
            <br/>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_last_modified}</strong> {content.tnc_last_modified_date}
            </p>
            
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_effective}</strong> {content.tnc_effective_date}
            </p>
            <br/>



            <h3 className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_overview_heading}
            </h3>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
              <strong>{content.tnc_heading_scope}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_scope_para1}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_scope_para2}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                <strong>{content.tnc_scope_list1_heading}</strong>{content.tnc_scope_list1}
            </li>
            <li>
                <strong>{content.tnc_scope_list2_heading}</strong>{content.tnc_scope_list2}
            </li>
            </ul>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_scope_para3}
            </p>
            <br />
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_scope_para4}
            </p>
            <br />
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_scope_para5}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_scope_para6}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_scope_para7}
            </p>

            <br />

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
              <strong>{content.tnc_data_controller_heading}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_controller_para1}
            </p>

            <br />
            <h3 className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_data_collections}
            </h3>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>A. {content.tnc_data_collections_para1}</strong> 
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_collections_para2}
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_collections_list1}
            </li>
            <li>
                {content.tnc_data_collections_list2}
            </li>
            <li>
                {content.tnc_data_collections_list3}
            </li>
            </ul>
            </p>
            <br />
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_collections_para3}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>1. {content.tnc_data_collections_para4}</strong>
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
                    <li>
                        <strong>{content.tnc_data_collections_list4_heading}</strong>{content.tnc_data_collections_list4}
                    </li>
                    <li>
                        <strong>{content.tnc_data_collections_list5_heading}</strong>{content.tnc_data_collections_list5}
                    </li>
                    <li>
                        <strong>{content.tnc_data_collections_list6_heading}</strong>{content.tnc_data_collections_list6}
                    </li>
                    <li>
                        <strong>{content.tnc_data_collections_list7_heading}</strong>{content.tnc_data_collections_list7}
                    </li>
                </ul>
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>2. {content.tnc_data_collections_para5}</strong>
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
                    <li>
                        <strong>{content.tnc_data_collections_list8_heading}</strong>{content.tnc_data_collections_list8}
                    </li>
                    <li>
                        <strong>{content.tnc_data_collections_list9_heading}</strong>{content.tnc_data_collections_list9}
                    </li>
                    <li>
                        <strong>{content.tnc_data_collections_list10_heading}</strong>{content.tnc_data_collections_list10}
                    </li>
                    <li>
                        <strong>{content.tnc_data_collections_list11_heading}</strong>{content.tnc_data_collections_list11}
                    </li>
                    <li>
                        <strong>{content.tnc_data_collections_list12_heading}</strong>{content.tnc_data_collections_list12}
                    </li>
                </ul>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>3. {content.tnc_data_collections_para6}</strong> 
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_collections_list13}
            </li>
            <li>
                {content.tnc_data_collections_list14}
            </li>
            <li>
                {content.tnc_data_collections_list15}
            </li>
            <li>
                {content.tnc_data_collections_list16}
            </li>
            <li>
                {content.tnc_data_collections_list17}
            </li>
            <li>
                {content.tnc_data_collections_list18}
            </li>
            <li>
                {content.tnc_data_collections_list19}
            </li>
            <li>
                {content.tnc_data_collections_list20}
            </li>
            <li>
                {content.tnc_data_collections_list21}
            </li>
            <li>
                {content.tnc_data_collections_list22}
            </li>
            </ul>
            </p>

          <br/>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>B. {content.tnc_data_use_para1}</strong>
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_use_para2}
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_use_list1}
            </li>
            <li>
                {content.tnc_data_use_list2}
            </li>
            <li>
                {content.tnc_data_use_list3}
            </li>
            <li>
                {content.tnc_data_use_list4}
            </li>
            <li>
                {content.tnc_data_use_list5}
            </li>
            <li>
                {content.tnc_data_use_list6}
            </li>
            </ul>
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_data_use_para4}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_use_para5}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_use_list7}
            </li>
            <li>
                {content.tnc_data_use_list8}
            </li>
            <li>
                {content.tnc_data_use_list9}
            </li>
            <li>
                {content.tnc_data_use_list10}
            </li>
            <li>
                {content.tnc_data_use_list11}
            </li>
            <li>
                {content.tnc_data_use_list12}
            </li>
            <li>
                {content.tnc_data_use_list13}
            </li>
            <li>
                {content.tnc_data_use_list14}
            </li>
            <li>
                {content.tnc_data_use_list15}
            </li>
            </ul>
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_data_use_para6}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_use_para7}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_use_list16}
            </li>
            <li>
                {content.tnc_data_use_list17}
            </li>
            <li>
                {content.tnc_data_use_list18}
            </li>
            <li>
                {content.tnc_data_use_list19}
            </li>
            </ul>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_data_use_para8}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_use_para9}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_use_list20}
            </li>
            <li>
                {content.tnc_data_use_list21}
            </li>
            <li>
                {content.tnc_data_use_list22}
            </li>
            </ul>
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_data_use_para10}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_use_para11}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_data_use_para12}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_use_para13}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_data_use_para14}</strong>
            </p>
            
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_use_list23}
            </li>
            <li>
                {content.tnc_data_use_list24}
            </li>
            <li>
                {content.tnc_data_use_list25}
            </li>
            <li>
                {content.tnc_data_use_list26}
            </li>
            </ul>
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_data_use_para15}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_use_para16}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_data_use_para17}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_use_para18}
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_data_use_para19}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_use_para20}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_use_list27}
            </li>
            <li>
                {content.tnc_data_use_list28}
            </li>
            <li>
                {content.tnc_data_use_list29}
            </li>
            <li>
                {content.tnc_data_use_list30}
            </li>
            <li>
                {content.tnc_data_use_list31}
            </li>
            </ul>
            </p>

            <br/>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                    <strong>C. {content.tnc_cookies_para1}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_cookies_para2}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_cookies_para3}
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_cookies_list1}
            </li>
            <li>
                {content.tnc_cookies_list2}
            </li>
            <li>
                {content.tnc_cookies_list3}
            </li>
            <li>
                {content.tnc_cookies_list4}
            </li>
            <li>
                {content.tnc_cookies_list5}
            </li>
            <li>
                {content.tnc_cookies_list6}
            </li>
            </ul>
            </p>

          <br/>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                    <strong>D. {content.tnc_data_sharing_para1}</strong>
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                    {content.tnc_data_sharing_para2}
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_data_sharing_para3}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>1. {content.tnc_data_sharing_para4}</strong>
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_sharing_para5}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_sharing_list7}
            </li>
            <li>
                {content.tnc_data_sharing_list8}
            </li>
            <li>
                {content.tnc_data_sharing_list9}
            </li>
            </ul>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>2. {content.tnc_data_sharing_para6}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_sharing_para7}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_sharing_list10}
            </li>
            <li>
                {content.tnc_data_sharing_list11}
            </li>
            </ul>
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>3. {content.tnc_data_sharing_para8}</strong>
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_sharing_para9}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>4. {content.tnc_data_sharing_para10}</strong>
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_sharing_para11}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_sharing_list12}
            </li>
            <li>
                {content.tnc_data_sharing_list13}
            </li>
            </ul>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>5. {content.tnc_data_sharing_para12}</strong>
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_sharing_para13}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>6. {content.tnc_data_sharing_para14}</strong>
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_sharing_para15}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_data_sharing_list14}
            </li>
            <li>
                {content.tnc_data_sharing_list15}
            </li>
            <li>
                {content.tnc_data_sharing_list16}
            </li>
            <li>
                {content.tnc_data_sharing_list17}
            </li>
            <li>
                {content.tnc_data_sharing_list18}
            </li>
            <li>
                {content.tnc_data_sharing_list19}
            </li>
            <li>
                {content.tnc_data_sharing_list20}
            </li>
            <li>
                {content.tnc_data_sharing_list21}
            </li>
            <li>
                {content.tnc_data_sharing_list22}
            </li>
            <li>
                {content.tnc_data_sharing_list23}
            </li>
            <li>
                {content.tnc_data_sharing_list24}
            </li>
            <li>
                {content.tnc_data_sharing_list25}
            </li>
            <li>
                {content.tnc_data_sharing_list26}
            </li>
            </ul>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>7. {content.tnc_data_sharing_para16}</strong>
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_sharing_para17}
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_sharing_para18}
            </p>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>8. {content.tnc_data_sharing_para19}</strong>
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.tnc_data_sharing_para20}
            </p>
          <br/>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                    <strong>E. {content.tnc_data_retention_deletion_para1}</strong>
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                    {content.tnc_data_retention_deletion_para2}
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_data_retention_deletion_para3}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_data_retention_deletion_para4}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_data_retention_deletion_para5}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_data_retention_deletion_para6}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                    <strong>F. {content.tnc_processing_para1}</strong>
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                    {content.tnc_processing_para2}
            </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_processing_para3}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>1. {content.tnc_processing_para4}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_processing_para5}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_processing_list1}
            </li>
            <li>
                {content.tnc_processing_list2}
            </li>
            <li>
                {content.tnc_processing_list3}
            </li>
            <li>
                {content.tnc_processing_list4}
            </li>
            <li>
                {content.tnc_processing_list5}
            </li>
            <li>
                {content.tnc_processing_list6}
            </li>
            </ul>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>2. {content.tnc_processing_para6}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_processing_para7}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_processing_para8}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_processing_para9}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>3. {content.tnc_processing_para10}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_processing_para11}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_processing_para12}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>4. {content.tnc_processing_para13}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_processing_para14}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_processing_para15}
          </p>
          <br/>
          <h3 className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_heading}
            </h3>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para1}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.tnc_choice_transparency_list1}
            </li>
            <li>
                {content.tnc_choice_transparency_list2}
            </li>
            <li>
                {content.tnc_choice_transparency_list3}
            </li>
            <li>
                {content.tnc_choice_transparency_list4}
            </li>
            </ul>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para2}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>1. {content.tnc_choice_transparency_para3}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para4}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_choice_transparency_para5}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para6}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para7}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para8}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_choice_transparency_para9}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para10}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para11}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_choice_transparency_para12}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para13}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para14}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_choice_transparency_para15}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para16}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.tnc_choice_transparency_para17}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para18}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>2. {content.tnc_choice_transparency_para19}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para20}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>3. {content.tnc_choice_transparency_para21}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para22}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para23}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>4. {content.tnc_choice_transparency_para24}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para25}
          </p>
          <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>5. {content.tnc_choice_transparency_para26}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para27}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para28}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para29}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para30}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para31}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para32}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_choice_transparency_para33}
          </p>
          <br/>
          <h3 className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_update_heading}
            </h3>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_update_para1}
          </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            {content.tnc_update_para2}
          </p>

          <br/>
           
          <br/>

        </div>
        </div>
      <Footer/>
    </div>
  );
}
