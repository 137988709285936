export const FirebaseConfig = {
	"projectId": "qkoamublance",
	"appId": "1:504958970954:web:fb4814e7aff752c1ad0ab3",
	"databaseURL": "https://qkoamublance-default-rtdb.firebaseio.com",
	"storageBucket": "qkoamublance.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDIxrME5kB8FQcVjnN_1tj0ZZ5GBwby4OQ",
	"authDomain": "qkoamublance.firebaseapp.com",
	"messagingSenderId": "504958970954",
	"measurementId": "G-RNVJKDG462"
};