import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const content = {
    "cancellations_policy": "Cancellation Policy",
    "cancellations_policy_para1": "Cancellations can be frustrating for riders and driver partners alike, which is why the cancellation fee is in place to ensure that drivers are fairly compensated for their time when committing to a trip.",
    "cancellations_policy_para2": "If you cancel within 5 minutes: You will not be charged a cancellation fee.",
    "cancellations_policy_para3": "If you cancel after 5 minutes: The cancellation fee for a cancelled trip will automatically be charged to your next trip.",
    "cancellations_policy_para4": "If driver number is shown to you or else you call to driver and then you cancel the trip cancellation charges will be 100 rs and driver will be blacklisted subjected to briebing.",
    "cancellations_policy_para5": "Before you cancel a trip, you will receive a notification to ensure that you are aware of the fee that will be added to your next trip.",
    "cancellations_policy_para6": "After completing your next trip, the total fare that you will pay will include the fare as well as the previous trip's cancellation fee.",
    "cancellations_policy_para7": "If you have cancelled multiple trips consecutively, the cancellation fees for the multiple trips will accumulate as arrears and prevent any more requests from going through. In such cases, you're expected to clear the arrears using an alternative payment method, such as a credit, debit card, etc. Once the payments in arrears have been cleared, you will be able to request an AmbiPalm as usual.",
    "cancellations_policy_para8": "Cancellation Fees:",
    "cancellation_fee_list1": "BLS: ₹100",
    "cancellation_fee_list2": "OTHERS: ₹100",
    "cancellation_fee_list3": "ALS: ₹200",
    "cancellations_policy_para9": "If you feel that a cancellation fee has been unfairly charged, please use the in-app Help feature (from the menu located on the top left corner of the Qko app) to bring the particular trip to our attention.",
    "cancellations_policy_para10": "Review my cancellation fee",
    "cancellations_policy_para11":"You may be charged a cancellation fee if you cancel a trip after you're matched with a driver. These fees pay driver-partners for the time and effort they spend getting to your location.",
    "cancellations_policy_para12":"Drivers are also able to cancel a ride request if they've waited a certain amount of time at the pickup location. You may be charged a cancellation fee, in this case, to reimburse your driver for their time.",
    "cancellations_policy_para13":"I was involved in an accident",
    "cancellations_policy_para14":"Qko is committed to the safety of everyone using our platform. If you have been involved in an accident, please make sure that you and all parties involved are safe. Immediately notify the police and medical personnel if necessary. You may reach out to our Customer Support within 30 minutes of your trip getting completed.",
    "cancellations_policy_para15":"Also, please let us know what happened by sharing details that will help us to support you. We will get in touch.",
    "cancellations_policy_para16": "Refund Policy",
    "cancellations_policy_para17": "If you are eligible for a Refund then the same will be refunded to the same source from where the charge was deducted within 7 working days as per your bank's Refund Policy",
    "cancellations_policy_para18": "Qko - Cancellation Policy — Driver",
    "cancellations_policy_para19": "As an independent contractor, you are able to choose whether to make yourself available to take a trip request sent to you by Qko, or to cancel an allocated booking.",
    "cancellations_policy_para20": "However we strongly recommend you go offline when you are not available to undertake trips. Qko reserves the right to enforce a breach of your contractual agreements or restrict access to the platform if you are found to be in breach of the cancellation policy.",
    "cancellations_policy_para21": "Without limitation, Qko considers the following cancellation behaviours to be in breach of the Services Agreement:",
    "cancellations_policy_list4": "refusing to complete a trip for a rider based on their intended destination",
    "cancellations_policy_list5": "refusing to complete a trip for your rider based on the fare multiple applied by Qko's Dynamic Pricing",
    "cancellations_policy_list6": "refusing to complete a trip for your rider for any reason that would be in breach of relevant discrimination laws and the conditions set out in your Private Hire Driver licence.",
    "cancellations_policy_para22": "Examples of evidence of committing or intending to commit such behaviour include:",
    "cancellations_policy_list7": "contacting a rider by phone and asking for the rider's destination before having arrived at the pick-up location.",
    "cancellations_policy_list8": "once aware of the destination, the ride, explicitly asking the rider to cancel the ride or behaving in an obstructive way to the natural flow of a trip in order to encourage the rider to cancel the trip.",
    "cancellations_policy_list9": "systematic and/or persistent cancellation of trips.",
    "cancellations_policy_list10": "refusing to complete a trip due to a discriminato reasons such as your rider's disability, gender, race or sexuality (for example)",
    "cancellations_policy_list11": "",
}

export default function CancellationRefundPolicy(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings); 
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
      <div className={classes.container}>
            <br/>
            <h2  style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color: "#383838",textDecoration: "none"}}>{content.cancellations_policy}</h2>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{content.cancellations_policy_para1}</p>
            <br/>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{content.cancellations_policy_para2}</p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{content.cancellations_policy_para3}</p>
            <br/>

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para4}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para5}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para6}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para7}
            </p>
            <br />
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.cancellations_policy_para8}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.cancellation_fee_list1}
            </li>
            <li>
                {content.cancellation_fee_list2}
            </li>
            <li>
               {content.cancellation_fee_list3}
            </li>
            </ul>
            </p>
            <br />

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para9}
            </p>
            <br />

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.cancellations_policy_para10}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para11}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para12}
            </p>
            <br />

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.cancellations_policy_para13}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para14}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para15}
            </p>
            <br />

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.cancellations_policy_para16}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para17}
            </p>

            <br />

            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                <strong>{content.cancellations_policy_para18}</strong>
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para19}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para20}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para21}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.cancellations_policy_list4}
            </li>
            <li>
                {content.cancellations_policy_list5}
            </li>
            <li>
                {content.cancellations_policy_list6}
            </li>
            </ul>
            </p>

            <br />
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
                {content.cancellations_policy_para22}
            </p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.cancellations_policy_list7}
            </li>
            <li>
                {content.cancellations_policy_list8}
            </li>
            <li>
                {content.cancellations_policy_list9}
            </li>
            <li>
                {content.cancellations_policy_list10}
            </li>
            </ul>
            </p>
          <br/>
          <br/>
        </div>
        </div>
      <Footer/>
    </div>
  );
}
