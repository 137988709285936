import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const content = {
    "product_description_para1": "QKO is a service to book & track an Ambulance and hospital,",
    "product_description_para2": "We provide world class platform bring all kind of emergency medical at one place. We connects people with everything they need to take good care of themselves and their family - assessing health issues, finding the right home health care, booking ambulance bookine hospitals, storing health records or learning new ways to live healthier",
    "product_description_para3": "Have you ever been in an emergency situation and felt helpless and anic stricken? Or have you ever been in a medical crisis due to in ambulance services? Then do not worry as now we have a solution for you — Qko, an ambulance mobile app of less MB designed to help you book & track the nearest ambulance via mobile and hospitals in time via call support.",
    "product_description_para4": "Qko aims to connect the customers to the nearest ambulance service providers. Emergency and Non- Emergency , ICU Ambulance, Mortuary Ambulance, Basic Ambulance. This service is provided through mobile app, is fully integrated and will have provisions to book & track your ambulance, get estimated fare/time to reach, digital payments etc. The same can be done by website for hospitals too.",
    "product_description_para5": "Qko ambulance services are not only limited to emer encies but can be used by a patient to schedule check-ups & appointments or simply to get home after being discharged from hospital. The mobile app connects users to emergency facilities by providing timely ambulance services when required with lab test and hospital assistance.",
    "product_description_list1": "Qko is a 24* 7 Ambulance Booking Service platform.",
    "product_description_list2": "Provides a quick and affordable ambulance at your doorstep.",
    "product_description_list3": "Available for emergency and non-emergency health situations.",
    "product_description_list4": "Ambulances are equipped with all the required medical facilities and health professionals.",
    "product_description_list5": "Provides a range of services.",
    "product_description_list6": "Book via call or website or app with inbuilt live location tracking feature.",
    "product_description_para6": "So, in case of medical emergency, book the nearest, Cheapest, Quickest ambulance via Qko App, be a hero & life saver!!",
}

export default function ProductDescription(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
        <div className={classes.container}>
            <br/>
            <h2 style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color: "#383838",textDecoration: "none"}}>{t('Product and Description')}</h2>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{content.product_description_para1}</p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{content.product_description_para2}</p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{content.product_description_para3}</p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{content.product_description_para4}</p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{content.product_description_para5}</p>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>
            <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
            <li>
                {content.product_description_list1}
            </li>
            <li>
                {content.product_description_list2}
            </li>
            <li>
                {content.product_description_list3}
            </li>
            <li>
                {content.product_description_list4}
            </li>
            <li>
                {content.product_description_list5}
            </li>
            <li>
                {content.product_description_list6}
            </li>
            </ul>
            </p>
            <br/>
            <p className={classes.description} style={{textAlign:isRTL === 'rtl'?'right':'left'}}>{content.product_description_para6}</p>
            
            <br/>
            
        </div>
        </div>

      <Footer />
    </div>
  );
}
